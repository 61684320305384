// import SignIn from "../../containers/SignIn";
import Terms from "../../containers/Terms";
// import TermsAgree from "../../containers/TermsAgree";
import YoutubeEntry from "../../containers/YoutubeEntry";
import InfluencerEntry from "../../containers/InfluencerEntry";
import Home from "../../containers/Home";
import Revenues from "../../containers/Revenues";
import MonthlyRevenues from "../../containers/MonthlyRevenues";
// import CreasUserFirstRegistration from "../../containers/CreasUserFirstRegistration";
import Payment from "../../containers/Payment";
import SnsLinkages from "../../containers/SnsLinkages";
import Profile from "../../containers/Profile";
import ProfileEdit from "../../containers/ProfileEdit";
import Question from "../../containers/Question";
// import LiveStreamingTerm from "../../containers/LiveStreamingTerm";
import VoomTerms from "../../containers/VoomTerms";
import UuumNetworkTerm from "../../containers/UuumNetworkTerm";
import GoogleAuthRedirectPage from "../../containers/GoogleAuthRedirectPage";
import ScoutEntry from "../../containers/ScoutEntry";
import ScoutEntryGroup from "../../containers/ScoutEntryGroup";
import LiveStreamingEntry from "../../containers/entries/LiveStreaming";
// import OauthLineCallback from "../../containers/OauthLineCallback";
import VoomFaq from "../../containers/VoomFaq";
import AppearInAdSurvey from "../../containers/AppearInAdSurvey";

interface PathObject {
  component: React.FC<any>;
}

type PrivatePathNames =
  | "/"
  | "/revenues"
  | "/creator/revenues/:year/:month"
  | "/payment"
  | "/sns_linkages"
  | "/profile"
  | "/profile/edit/:pathAttribute"
  | "/question"
  | "/voom_faq"
  | "/voom_terms/"
  | "/appear_in_ad_survey";

type PublicPathNames =
  // | "/sign_in"
  | "/entry/youtube"
  | "/entry/influencer"
  | "/entry/live_streaming"
  | "/terms"
  // | "/terms/agree"
  // | "/users/creas/first_registration"
  | "/entry/google/oauth"
  | "/scout_entry/solo/:uuid"
  | "/scout_entry/group/:uuid"
  // | "/live_streaming_term/"
  // | "/oauth/line/callback"
  | "/uuum_network_term";

type PublicPaths = {
  [key in PublicPathNames]: PathObject;
};

type PrivatePaths = {
  [key in PrivatePathNames]: PathObject;
};

const privatePaths: PrivatePaths = {
  "/": {
    component: Home,
  },
  "/revenues": {
    component: Revenues,
  },
  "/creator/revenues/:year/:month": {
    component: MonthlyRevenues,
  },
  "/payment": {
    component: Payment,
  },
  "/sns_linkages": {
    component: SnsLinkages,
  },
  "/profile": {
    component: Profile,
  },
  "/profile/edit/:pathAttribute": {
    component: ProfileEdit,
  },
  "/question": {
    component: Question,
  },
  "/voom_faq": {
    component: VoomFaq,
  },
  "/voom_terms/": {
    component: VoomTerms,
  },
  "/appear_in_ad_survey": {
    component: AppearInAdSurvey,
  },
};

const publicPaths: PublicPaths = {
  // "/sign_in": {
  //   component: SignIn,
  // },
  "/entry/youtube": {
    component: YoutubeEntry,
  },
  "/entry/influencer": {
    component: InfluencerEntry,
  },
  "/entry/live_streaming": {
    component: LiveStreamingEntry,
  },
  "/terms": {
    component: Terms,
  },
  // "/terms/agree": {
  //   component: TermsAgree,
  // },
  // "/users/creas/first_registration": {
  //   component: CreasUserFirstRegistration,
  // },
  "/entry/google/oauth": {
    component: GoogleAuthRedirectPage,
  },
  "/scout_entry/solo/:uuid": {
    component: ScoutEntry,
  },
  "/scout_entry/group/:uuid": {
    component: ScoutEntryGroup,
  },
  // "/live_streaming_term/": {
  //   component: LiveStreamingTerm,
  // },
  "/uuum_network_term": {
    component: UuumNetworkTerm,
  },
  // "/oauth/line/callback": {
  //   component: OauthLineCallback,
  // },
};

export const pathType = (pathname: string): "public" | "private" => {
  if (Object.keys(privatePaths).includes(pathname)) return "private";
  else return "public";
};

export const paths = { ...privatePaths, ...publicPaths };
